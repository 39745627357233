<template>
  <div id="lawyersDetails" style="padding-top: 126px">
    <app-header />
    <!-- 位置 -->
    <div class="current_position w">
      <div class="current_position_text">当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, i) in $route.meta.breadcrumb"
          :to="item.path ? { path: item.path } : ''"
          :key="i"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <!-- 详情 -->
    <div class="particularsList w">
      <div class="left">
        <img :src="particularsList.lawyer_img" alt="" />
      </div>
      <div class="right">
        <div class="th">{{ particularsList.full_name }}</div>
        <div class="tb">
          性别: {{ particularsList.sex }}
          <span>职务: {{ particularsList.job }}</span>
        </div>
        <div class="tb">职业证号: {{ particularsList.license_no }}</div>
        <div class="tb">职位: {{ particularsList.title }}</div>
        <div class="tb">工作单位: {{ particularsList.work_unit }}</div>
      </div>
    </div>
    <!-- 主要工作简历 -->
    <div class="business">
      <!-- 1 -->
      <div class="business_th w">
        <div class="box"></div>
        <div>主要工作简历</div>
      </div>
      <div class="business_tb w">
        {{ particularsList.job_profile }}
      </div>
      <!-- 2 -->
      <div class="business_th w">
        <div class="box"></div>
        <div>服务倾向</div>
      </div>
      <div class="business_tb w">
        {{ particularsList.service_tendency }}
      </div>
      <!-- 3 -->
      <div class="business_th w">
        <div class="box"></div>
        <div>突出业绩</div>
      </div>
      <div class="business_tb w">
        {{ particularsList.achievement }}
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import Header from "../../components/Header/header.vue";
import Footer from "../../components/Footer/footer.vue";
import { lawyer_info } from "../../api/policies.js";
export default {
  data() {
    return {
      particularsList: {}, //机构详情
    };
  },
  created() {
    this.lawyer_info();
    console.log(76, this.$route.params.id);
  },
  methods: {
    async lawyer_info() {
      let id = this.$route.params.id;
      const parameter = {
        id,
      };

      const { result } = await lawyer_info(parameter);

      this.particularsList = result;
      console.log("this.particularsList", this.particularsList);
    },
  },
  components: { "app-header": Header, "app-footer": Footer },
};
</script>

<style scoped>
@import url("./lawyersDetails.css");
</style>
